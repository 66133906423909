import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MobileSvg } from '../svgs/mobile-svg'
import { UIXSvg } from '../svgs/ui-ux-svg'
import { WebSvg } from '../svgs/web-svg'
import DevCard from '../components/DevCard'
import { theme } from '../theme/theme'

const useStyles = makeStyles({
  Container: {
    minHeight: '100vh',
  },
  Body1: {
    paddingLeft: '8px',
    fontSize: '1.25rem',
    color: theme.colors.grey,
    [theme.breakpoints.up('xl')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  Services: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '3rem '
  },
  Heading: {
    fontWeight:'bold',
    color: theme.colors.bronze,
    [theme.breakpoints.up('xl')]: {
      fontSize: '4rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem'
    }
  },
})

const services = [
  {
    svg: <WebSvg />,
    title: 'Web development',
    text: 'Using the latest development trends to keep up with the best developed sites and web applications out there. '
  },
  {
    svg: <MobileSvg />,
    title: 'Mobile development',
    text: 'Utilizing flexible app development libraries like React Native to take your web experience to both iOS and Android.'
  },
  {
    svg: <UIXSvg />,
    title: 'UI/UX',
    text: 'All designs and experiences are created in house and can be created for both mobile and the web.'
  },
]

export default function MidHeader() {
  const classes = useStyles()
  return (
    <Grid 
    container
    className={classes.Container}
    >
      <Grid item>
        <Typography 
        variant="h2"
        className={classes.Heading}
        >
          Think.Solve.Create
        </Typography>
        <Typography 
        variant="body1"
        className={classes.Body1}
        >
          Lets build something amazing together
        </Typography>   
      </Grid>
      <Grid 
      container
      className={classes.Services}
      >
        {services.map((service, index) => (
          <DevCard
          key={index}
          Svg={service.svg}
          Heading={service.title}
          Text={service.text}
          />
        ))}
      </Grid>
    </Grid>
  )
}