import React from 'react'
import { Grid, Typography, SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../theme/theme'

const useStyles = makeStyles({
  Container: {
    maxWidth: 400,
    textAlign: 'center',
    margin: '2.5rem',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'transform 0.1s ease 0.1s'
   },
  },
  Svg: {
    height: 350,
    width: 350,
    [theme.breakpoints.down('md')]: {
      height: 300,
      width: 300,
    }
  },
  Heading: {
    padding: '0.5rem 0',
    color: theme.colors.grey
  },
  Text: {
    padding: '0.5rem 0',
    fontSize: '2rem',
    color: theme.colors.grey
  }
})

export default function DevCard({Svg, Heading, Text }) {
  const classes = useStyles()

  return (
    <Grid 
    container
    direction="column"
    justify="center"
    alignItems="center"
    className={classes.Container}
    >
      <SvgIcon className={classes.Svg}>
        {Svg}
      </SvgIcon> 
      <Typography 
      variant="h4"
      className={classes.Heading}
      >
        {Heading}
      </Typography>
      <Typography 
      variant="body1"
      classes={classes.Text}
      >
        {Text}
      </Typography>
    </Grid>
  )
}