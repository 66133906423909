import React from 'react';
import { Container } from '@material-ui/core'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { theme } from '../src/theme/theme'
import Header from '../src/components/Header'
import MidHeader from '../src/components/MidHeader'
import Nav from '../src/components/Nav'
import ContactUs from '../src/components/ContactUs'
import image from '../src/svgs/Untitled-1.png'


const useStyles = makeStyles(theme => ({
  Container: {
    width: '100%',
    padding: '0 6rem',
    overflow: 'hidden',
    background: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down("md")]: {
      padding: '0 2rem',
    },
    [theme.breakpoints.down("sm")]: {
      padding: '0 1rem',
      width: '100%'
    },
    [theme.breakpoints.down("xs")]: {
      padding: '0 0',
   
    },
    
  }
}))

function App() {
  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <Container 
      maxWidth="xl"
      className={classes.Container}
      // style={{ background: `url(${image})`, backgroundRepeat: 'no-repeat'}}
      >
        <Header />
        <MidHeader />
        <ContactUs />
      </Container>
      <Nav 
        align="center"
        color={theme.colors.bronze}
        position="absolute"
        type="h4"
      />
    </ThemeProvider>
  );
}

export default App;
