import React from 'react'
import { Grid } from '@material-ui/core'
import Address from './Address'


export default function Nav({align, type="body1", color="white", position='flex'}) {
  const [textColor, setTextColor] = React.useState("black")

  React.useEffect(() => {
    if(color !== "white") setTextColor("white")
  },[])

  return (
    <Grid 
    container 
    direction="row"
    justify={align}
    alignItems="center"
    style={{background: `${color}`, height: '5rem', position: 'absolute', left: 0}}
    
    >
      <Address size={type} color={textColor}/>
    </Grid>
  )
}