import React from 'react'
import { Grid, Typography } from '@material-ui/core'

export default function Address({size = 'h3', color = 'black'}) {
  return (
    <Grid item>
      <Typography 
        variant={size} 
        style={{color: `${color}`}}
      >
          rtwhite.dev
      </Typography>
    </Grid>
  )
}