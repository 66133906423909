import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: 'white',
      },
    },
  },
  colors: {
    darkBlue: '#001524',
    blue: '#192B39',
    grey: '#707070',
    bronze: '#685455',
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "roboto-slab, serif"
    },
    h2:{
      fontFamily: "roboto-slab, serif"
    },
    h3:{
      fontFamily: "roboto-slab, serif"
    },
    h4:{
      fontFamily: "roboto-slab, serif"
    }
  }
});