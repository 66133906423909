import React from 'react'
import { Grid, Typography, SvgIcon, Link } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import { Email } from '../svgs/email' 
import { Github } from '../svgs/github'
import { Linkedin } from '../svgs/linkedin'
import { theme } from '../theme/theme'

const useStyles = makeStyles({
  Container: {
    height: '35vh',
    justifyContent: 'flex-end'
  },
  SubContainer: {
    justifyContent: 'center',
    borderRadius: '25px',
    height: '100%', 
    width: '90%',
    background: '#fff',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  Svg: {
    height: 40,
    width: 40,
    margin: '.5rem .25rem',
    padding: '.5rem .25rem',
    // color: theme.colors.bronze
  },
  Backdiv: {
    position: 'absolute',
    width: '100%',
    height: '2.5rem',
    background: '#685455',
    zIndex: -1
  },
  title:{
    fontWeight: 'bold',
    color: theme.colors.grey
  },
  text: {
    fontSize: '1.25rem',
    fontWeight: '400',
    color: theme.colors.grey
  },
  link: {
    background: theme.colors.bronze
  }
})

export default function ContactUs() {
  const classes = useStyles()
  return (
    <Grid 
    container
    direction="column"
    alignItems="center"
    className={classes.Container}
    >
      <Grid 
      className={classes.SubContainer}
      container
      direction="column"
      alignItems="center"
      >
        <SvgIcon className={classes.Svg} >
          <Link
          target="_blank"
          rel="noopener"
          href="mailto:rt.whitejr91@gmail.com"
          className={classes.Svg}
          style={{color: 'black'}}>
            <Email />
          </Link>
        </SvgIcon>
        <Typography 
        variant="h4"
        className={classes.title}>
          Let's get in touch
        </Typography>
        <Typography 
        variant="body2"
        className={classes.text}>
          Consultations are always free, and I am available to chat anytime.
        </Typography>
        <Grid item>
          <SvgIcon className={classes.Svg}>
            <Link 
            href="https://github.com/rtwhite10"
            target="_blank"
            rel="noopener"
            className={classes.Svg}
            style={{color: 'black'}}>
              <Github />
            </Link>
          </SvgIcon>
          <SvgIcon className={classes.Svg}>
            <Link
            href="https://www.linkedin.com/in/robert-white-bb8177102/"
            target="_blank"
            rel="noopener"
            className={classes.Svg}
            style={{color: 'black'}}>
              <Linkedin />
            </Link> 
          </SvgIcon>
        </Grid>
      </Grid>
      <div
      className={classes.Backdiv}
      ></div>
    </Grid>
  )
}