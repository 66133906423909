import React from 'react'
import { Grid,  Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../theme/theme';
import mobile from '../svgs/cubemobileAsset.png'
import mobilesm from '../svgs/cubemobileAssetsm.png'
import image from '../svgs/Untitled-1.png'

const useStyles = makeStyles({
  Container: {
    maxHeight: '90vh',
    alignItems: 'center',
    alignContent: 'space-around',
    // [theme.breakpoints.down('md')]: {
    //   background: `url(${mobile})`,
    //   backgroundPosition: 'center bottom',
    //   backgroundRepeat: 'no-repeat',
    //   marginBottom:'2.5rem'
    // },
    [theme.breakpoints.down('sm')]: {
      background: `url(${mobilesm})`,
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat'
    },
  },
  Heading: {
    color: theme.colors.grey,
    letterSpacing: '2px'
  },
  Title:{
    fontWeight: 'bold',
    fontSize: '3rem',
    marginBottom: '2rem',
    letterSpacing: '2px'
  },
  TextContainer: {
    padding: '2rem 0',
    height: '80vh',
    width: '500px',
    alignItems: 'flex-start',
    
  },
  Description: {
    fontWeight: 'bold',
    color: theme.colors.grey,
    letterSpacing: '2px',
    fontSize: '1.25rem'
  }
})

export default function Header() {
  const classes = useStyles()
  return (
      <Grid 
     container
     direction="row"
     className={classes.Container}
    >
      <Grid 
      item
      className={classes.TextContainer}
      >
        <Typography 
        variant="h4"
        className={classes.Heading}>
          Hi my name is,
        </Typography>
        <Typography 
        variant="h1"
        className={classes.Title}
        >
          R.T. White
        </Typography>
        <Typography 
        variant="body1"
        className={classes.Description}>
        I’m a javaScript developer who also creates beautiful UI/UX experiences. Currently living out of San Diego, California. 
        </Typography>
      </Grid>
    </Grid>
  )
}